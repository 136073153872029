<template>
    <div class="row">
        <div class="col-12">
            <h4>{{ $t('global.ajout_cheval') }}</h4>
        </div>
        <div class="col-12">
            <div class="alert alert-info" v-if="info_nb_horse">{{ info_nb_horse_msg }}</div>
            <form @submit.prevent="checkForm">

                <!-- Nom -->
                <div class="form-group">
                    <label>{{ $t('horse.form.nom') }}<sup>*</sup></label>

                    <div class="input-group">
                        <input 
							class="form-control" 
							type="text" 
							:placeholder="$t('horse.form.nom')" 
							v-model="horse_nom" id="horse_nom" 
							required
							:disabled="!can_proceed"
							>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <!-- Sexe -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.sexe') }}<sup>*</sup></label>
							<e-select
								v-model="horse_sexe"
								id="sexe_code"
								track-by="sexe_code"
								label="sexe_label"
								:placeholder="$t('horse.placeholder.sexe')"
								:selectedLabel="$t('select.selectedLabel')"
								:options="horse_sexes"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingHorseSexe"
								:show-labels="false"
								:disabled="!can_proceed"
							>
								<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- Robe -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.robe') }}</label>
							<e-select
								v-model="horse_robe"
								id="robe_code"
								track-by="robe_code"
								label="robe_label"
								:placeholder="$t('horse.placeholder.robe')"
								:selectedLabel="$t('select.selectedLabel')"
								:options="horse_robes"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingHorseRobe"
								:show-labels="false"
								:disabled="!can_proceed"
							>
								<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- Race -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.race') }}</label>
							<e-select
								v-model="horse_race"
								id="race_code"
								track-by="race_code"
								label="race_label"
								:placeholder="$t('horse.placeholder.race')"
								:selectedLabel="$t('select.selectedLabel')"
								:options="horse_races"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingHorseRace"
								:show-labels="false"
								:disabled="!can_proceed"
							>
								<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
                        </div>
                    </div>
                </div>

                <!-- Date de naissance -->
                <div class="form-group">
                    <label>{{ $t('horse.form.datenaissance') }}</label>
                    <e-datepicker 
						id="date" 
						v-model="horse_datenaissance"
						:disabled="!can_proceed"
					></e-datepicker>
                </div>

                <!-- Message d'erreur si besoin -->
                <WarnAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                <!-- Bouton de validation du formulaire -->
                <div class="text-center mt-5">
                    <button 
						v-if="!saved && can_proceed" 
						type="submit" 
						class="btn btn-primary rounded-pill"
						:disabled="!can_proceed"
					>
                        {{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']"/>
                    </button>

					<button 
						v-if="!can_proceed"
						type="submit" 
						class="btn btn-secondary rounded-pill"
						@click.prevent="skipForm"
					>
                        {{ $t('global.skip') }} <font-awesome-icon :icon="['fal', 'times']"/>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script type="text/javascript">
    import HorseMixin from '@/mixins/Horse'
	import PlanningMonte from '@/mixins/PlanningMonte'
	import Actes from '@/mixins/Actes'
	import Gynecologie from '@/mixins/Gynecologie'
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'

    export default {
        name: "HorseCreationTriggered",
        props: {
            horses_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => new Date() },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] },
            actes_ids: { type: Array, default: () => [] },
        },
        mixins: [Actes, Gynecologie, HorseMixin, PlanningMonte, Shutter, ShutterTriggers],
        data () {
            return {
                horse_races: [],
                horse_robes: [],
				horse_sexes: [],
				isLoadingHorseSexe: false,
				isLoadingHorseRobe: false,
				isLoadingHorseRace: false,

                horse_nom: null,
                horse_race: null,
                horse_robe: null,
                horse_sexe: null,
				horse_datenaissance: null,
				horse_mere: null,
				mere_pedigree: null,
				pedigree: null,
				season_mare_stallion: null,

                required_values: [
                        'horse_nom',
                        'horse_race',
                        'horse_sexe',
                        'horse_robe'
                    ],
                message_erreur_code: '',
                triggered_list: [],
                saved: false,
				can_proceed: false,
                nb_horse: 0,
				info_nb_horse: false,
				info_nb_horse_msg: ''
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.check_nb_horses()
                this.triggered_list = this.next

				this.horse_races = await this.getRaces()
				this.horse_robes = await this.getRobes()
				this.horse_sexes = [
                    {
                        sexe_code: "M",
                        sexe_label: this.getTrad('poulinage.colt')
                    },
                    {
                        sexe_code: "F",
                        sexe_label: this.getTrad('poulinage.filly')
                    }
                ]

				this.isLoadingHorseSexe = false
				this.isLoadingHorseRobe = false
				this.isLoadingHorseRace = false

				this.can_proceed = true

				const mere_porteuse = await this.getHorseById(this.horses_ids[0])
				let mere_donneuse = mere_porteuse

				// Récupérer le season_mare_stallion de la réponse de l'acte
				this.season_mare_stallion = await this.getSeasonMareStallionFromActe(this.actes_ids[0])

				// Si il y a une porteuse, ça veut dire que this.horses_ids est la porteuse donc on va chercher la donneuse
				if(this.season_mare_stallion && this.season_mare_stallion.seasonmarestallion_porteuse) {

					const seasonmare_donneuse = await this.getSeasonMareById(this.season_mare_stallion.seasonmarestallion_seasonmare)
					if(seasonmare_donneuse) {
						mere_donneuse = await this.getHorseById(seasonmare_donneuse.seasonmare_horse)
					}
				}

                this.horse_datenaissance = this.date_acte
                this.horse_robe = this.horse_robes.find(robe => robe.robe_code === 'UKN')
				this.horse_mere = mere_donneuse
				this.horse_race = {
					race_code: this.horse_mere.horse_race,
					race_label: this.horse_mere.race_label
				}
                const format = await this.getCurrentFormatPoulain()
                if(!format){
                    this.horse_nom = `N${this.date_acte.getFullYear()} ${mere_porteuse.horse_nom}`
                } else {
                    switch (format.pused_format) {
                        case 1: // pconfig.format_default "N. {reduced year} {mother's name}",
                            this.horse_nom = `N${this.date_acte.getFullYear().toString().substring(2, 4)} ${mere_porteuse.horse_nom}`
                            break;
                        case 2: // pconfig.format_fynm "N. {full year} {mother's name}",
                            this.horse_nom = `N${this.date_acte.getFullYear()} ${mere_porteuse.horse_nom}`
                            break;
                        case 3: // pconfig.format_nmy "{mother's name} {reduced year}",
                            this.horse_nom = `${mere_porteuse.horse_nom} ${this.date_acte.getFullYear().toString().substring(2, 4)}`
                            break;
                        case 4: // pconfig.format_nmfy "{mother's name} {full year}"
                            this.horse_nom = `${mere_porteuse.horse_nom} ${this.date_acte.getFullYear()}`
                            break;
                        default:
                            this.horse_nom = `N${this.date_acte.getFullYear()} ${mere_porteuse.horse_nom}`
                            break;
                    }
                }
            },
			
			async loadPedigree(horse_id) {
				this.pedigree = await this.getHorsePedigree(horse_id)
				if(!this.pedigree) {
					return false
				}

				let mere_sire = this.horse_mere.horse_sire
				if(!mere_sire) {
					mere_sire = `_${this.horse_mere.horse_id}`
				}

				const mere_sireeencode = await this.saveParentSireencode(this.pedigree.pedigree_id, 2, mere_sire)
                if(mere_sireeencode) {
                    this.pedigree['pedigree_sireencode_2'] = mere_sireeencode
                }


				// Pédigree de la mère
				this.mere_pedigree = await this.getHorsePedigree(this.horse_mere.horse_id)
				if(this.mere_pedigree) {
					this.pedigree['pedigree_nom_2'] = this.horse_mere.horse_nom
					this.pedigree['pedigree_nom_5'] = this.mere_pedigree['pedigree_nom_1']
					this.pedigree['pedigree_nom_6'] = this.mere_pedigree['pedigree_nom_2']
					this.pedigree['pedigree_nom_11'] = this.mere_pedigree['pedigree_nom_3']
					this.pedigree['pedigree_nom_12'] = this.mere_pedigree['pedigree_nom_4']
					this.pedigree['pedigree_nom_13'] = this.mere_pedigree['pedigree_nom_5']
					this.pedigree['pedigree_nom_14'] = this.mere_pedigree['pedigree_nom_6']
				}

				// Pedigree du père
				if(this.season_mare_stallion) {
					this.horse_pere = await this.getHorseById(this.season_mare_stallion.seasonmarestallion_horse)
					let pere_pedigree = await this.getHorsePedigree(this.horse_pere.horse_id)

					let pere_sire = this.horse_pere.horse_sire
					if(!pere_sire) {
						pere_sire = `_${this.horse_pere.horse_id}`
					}

					const pere_sireeencode = await this.saveParentSireencode(this.pedigree.pedigree_id, 1, pere_sire)
                    if(pere_sireeencode) {
                        this.pedigree['pedigree_sireencode_1'] = pere_sireeencode
                    }

					if (pere_pedigree) {
						this.pedigree['pedigree_nom_1'] = this.horse_pere.horse_nom
						this.pedigree['pedigree_nom_3'] = pere_pedigree['pedigree_nom_1']
						this.pedigree['pedigree_nom_4'] = pere_pedigree['pedigree_nom_2']
						this.pedigree['pedigree_nom_7'] = pere_pedigree['pedigree_nom_3']
						this.pedigree['pedigree_nom_8'] = pere_pedigree['pedigree_nom_4']
						this.pedigree['pedigree_nom_9'] = pere_pedigree['pedigree_nom_5']
						this.pedigree['pedigree_nom_10'] = pere_pedigree['pedigree_nom_6']
					}
				}

				// Indique si le pedigree a été remplit ou non
                return Object.keys(this.pedigree).length > 0
			},

            async checkForm() {
                let el = ''
                let error = false
                this.message_erreur_code = ""

                // On vérifie chaque input obligatoire
                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    // On ajoute la classe error sur les div des v-model non remplis
                    if(this[el] === null || this[el] === undefined) {
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
                    return false
                }
                this.saved = true

                let horse = {
                    horse_nom: this.horse_nom,
                    horse_sexe: this.horse_sexe.sexe_code,
                    horse_robe: this.horse_robe.robe_code,
                    horse_race: this.horse_race.race_code,
                    horse_datenaissance: this.horse_datenaissance
                }
                let foal_id = await this.createHorseOffline(horse)
                
                if(foal_id !== 0) {
					const pedigreeLoaded = await this.loadPedigree(foal_id)
                    if(pedigreeLoaded) await this.update_pedigree_horse_manual(foal_id, this.pedigree)

					// Save l'ID du foal dans le résultat
					await this.setActePoulain(this.actes_ids[0], foal_id)

                    const horse_tiers = await this.loadTiersCurrentPart(this.horses_ids[0])
                    if(horse_tiers.tiers_horse && horse_tiers.tiers_horse.length > 0) {
                        const tiers_horse = horse_tiers.tiers_horse
                        const to_send = tiers_horse.map(function(tier) {
                            return {
                                tiers_id: tier.tiers.tiers_id,
                                tiershorse_id: null,
                                tiershorsepart_frais: tier.tiers_horse_part.tiershorsepart_frais,
                                tiershorsepart_gains: tier.tiers_horse_part.tiershorsepart_gains,
                                tiershorsepart_pension: tier.tiers_horse_part.tiershorsepart_pension,
                                tiershorsepart_propriete: tier.tiers_horse_part.tiershorsepart_propriete,
                                tiershorsepart_contract: tier.tiers_horse_part.tiershorsepart_contract
                            }
                        })
                        const horse_id = this.$sync.replaceWithReplicated('horse', foal_id)
                        await this.addHorseTiers(
                            foal_id,
                            this.horse_datenaissance,
                            to_send
                        );
                    }
                    this.getTrigger(foal_id)
                    return true
                }
                this.message_erreur_code = "error.LEP"
                return false
            },

            getTrigger(horse_id) {
                this.setupActPropositionPoulain(horse_id, this.horse_datenaissance, this.horses_ids[0], (result) => {
                    result.forEach(triggered => {
                        this.triggered_list.push({
                            type: triggered.type,
                            horses: triggered.horses,
                            date: triggered.date,
                            actes_ids: [triggered.id]
                        })
                    })
                    this.nextTrigger(horse_id)
                },() => {
                    this.nextTrigger(horse_id)
                })
            },

            nextTrigger(horse_id) {
                this.shutterPanel().close('proposition-acte')
                this.shutterPanel().close('horse-selection')

                if(this.triggered_list.length > 0) {
                    const triggered = this.triggered_list[0]
                    this.triggered_list.shift()
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.triggered_list, null, triggered.actes_ids)
                } else {
                    this.$router.push({ name: 'horseFiche', params: {horse_id:horse_id} })
                }
            },
			skipForm() {
                this.$router.push({ name: 'horseListe' })
			},
            async check_nb_horses() {
				this.limit_horse = this.getUserHorseLimit()
				this.nb_horse = await this.getNbHorse()
				if(this.limit_horse - this.nb_horse < 10 && this.limit_horse - this.nb_horse > 0)
				{
					if(this.limit_horse - this.nb_horse == 1) {
						this.info_nb_horse_msg = this.getTrad('global.info_limit_horse_unique', [this.limit_horse - this.nb_horse])
					}
					else {
						this.info_nb_horse_msg = this.getTrad('global.info_limit_horse_multiple', [this.limit_horse - this.nb_horse])
					}
					
					this.info_nb_horse = true
				}
			},
        },
        watch: {
            'date_acte' (val){
                this.horse_datenaissance = val
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            WarnAlert: () => import('GroomyRoot/components/Alert/WarnAlert'),
        }
    };
</script>
